@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
body {
  font-family: "Montserrat", sans-serif !important;
  background-color: #efefef;
}

.hero-section {
  height: 100vh;
  background-size: cover;
  background-position: center;
}
.hero-section .hero-content {
  max-width: 700px;
}
.hero-section h6 {
  color: #ff97f8;
  padding-top: 200px;
  font-weight: 600;
}
.hero-section h1 {
  font-size: 50px;
  color: #fff;
  font-weight: 800;
}
.hero-section h5 {
  color: #fff;
  font-size: 15px;
}
.hero-section a {
  background-color: #7768cc;
  color: #fff;
  margin-top: 20px;
  display: inline-block;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 500;
  padding: 10px 20px;
}
.hero-section a.knowmore {
  background-color: #fff;
  color: #7768cc;
  margin-left: 20px;
}

@media (max-width: 800px) {
  .hero-section h1 {
    font-size: 25px;
    padding-top: 20px;
  }
  .hero-section h5 {
    font-size: 14px;
    padding-top: 20px;
    line-height: 20px;
  }
  .hero-section h6 {
    padding-top: 250px;
  }
}

/* Highlights */

.highlights {
  margin-top: -50px;
}
.highlights .container {
  background-color: #1e1a33;
  color: #fff;
  padding: 50px;
  text-align: center;
  box-shadow: 0px 30px 90px #0000003d;
}
.highlights h6 {
  font-size: 18px;
  font-weight: 600;
}
.highlights img {
  padding-bottom: 20px;
}

/* communitymanager */

.communitymanager {
  margin-top: 50px;
}
.communitymanager img {
  height: 400px;
}
.communitymanager h4 {
  font-weight: 700;
  font-size: 30px;
}
.communitymanager p {
  color: #1e1a33d1;
  font-weight: 500;
  font-size: 14px;
}
.communitymanager ul {
  list-style: none;
  font-weight: 600;
  font-size: 14px;
  padding: 0px;
  color: #1e1a33d1;
}

.communitymanager ul li:before {
  content: "✓";
  color: #7768cc;
  padding-right: 10px;
  font-weight: 900;
}

/* communityexperience */

.communityexperience {
  padding-top: 80px;
}
.communityexperience h2 {
  font-weight: 800;
}
.communityexperience p {
  color: #230d94d1;
  font-weight: 500;
  font-size: 15px;
  /* max-width: 600px; */
}
.communityexperience img {
  height: 100px;
}
.communityexperience .col-md-3 {
  background-color: #fff;
  margin: 20px;
  padding: 10px;
  box-shadow: 0px 3px 6px #00000029;
}

/* communnity events */

.communityevents {
  background-color: #1e1a33;
  color: #fff;
  margin-top: 40px;
  padding: 50px;
}
.communityevents h4 {
  font-weight: 600;
  font-size: 30px;
}
.communityevents p {
  color: #d3cbfcd1;
  padding-top: 20px;
}
.communityevents h6 {
  font-weight: 500;
  font-size: 25px;
}
.communityevents h5 {
  font-size: 15px;
  font-weight: 300;
}
.communityevents .col-5 {
  border: 1px solid #7768cc;
  margin: 10px;
  padding: 20px;
  text-align: center;
}
.communityevents a {
  background-color: #fff;
  color: #1e1a33;
  font-weight: 600;
  padding: 10px 20px;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;
}

@media (max-width: 800px) {
  .communityevents .col-5 {
    margin: 5px;
    padding: 15px;
  }
  .communityevents h5 {
    font-size: 12px;
  }
  .communitymanager img {
    height: auto;
  }
}

/* Testimonal */

.testimonal {
  margin-top: 50px;
}
.testimonal h1 {
  font-weight: 700;
  font-size: 30px;
}
.testimonal h6 {
  font-weight: 400;
  font-size: 15px;
  color: #9482ff;
}
.testimonal .bg-white {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  margin: 10px;
  border-radius: 5px;
}
.testimonal p {
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
}
.testimonal p:after {
  content: "";

  position: absolute;
  margin-top: -6px;
  margin-left: -5px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #fff;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);

  /* right: -15px; */
  /* top: 10px; */
  left: 38px;
  bottom: 73px;
}

.testimonal h5 {
  font-size: 13px;
  font-weight: 600;
}
.testimonal h4 {
  color: #7768cc;
  font-weight: 600;
  font-size: 13px;
}
.testimonal img {
  /* margin-top: 50px; */
  border-radius: 50%;
}

/* Portfolio */

.portfolio {
  height: 500px;
  background-size: cover;
  background-position: center;
}
.portfolio-details {
  margin-top: -300px;
}
.portfolio-details h1 {
  font-size: 30px;
  color: #fff;
  padding-bottom: 100px;
}
.portfolio-details h6 {
  font-size: 20px;
  font-weight: 600;
  padding-top: 20px;
}
.portfolio-details p {
  color: #6a6680;
  font-size: 14px;
  font-weight: 500;
}
.portfolio-details a {
  color: #9482ff;
  font-size: 14px;
  font-weight: 500;
}
.portfolio-details h2 {
  text-align: center;
  color: #1e1a33;
  font-size: 17px;
  padding-bottom: 40px;
}
@media (max-width: 800px) {
  .portfolio-details h1 {
    font-size: 22px;
  }
  .portfolio-details img {
    padding-top: 30px;
  }
}

/* Footer */

.footer {
  background-color: #1e1a33;
  padding: 30px;
}
.footer h6 {
  color: #f5f5f7;
  font-size: 13px;
}
.footer h6 a {
  color: #fff;
}
.footer h5 {
  color: #fff;
}
.footer img {
  width: 100px;
}

/* Contacts */
.contact {
  height: 500px;
  background-size: cover;
  background-position: center;
}
.contact-details {
  margin-top: -300px;
}
.contact-details h1 {
  font-size: 30px;
  color: #fff;
  padding-bottom: 100px;
}
.contact-details iframe {
  box-shadow: 0px 30px 60px #0000001a;
}
.contact-details small {
  font-size: 15px;
  color: #9482ff;
  font-weight: 600;
}
.contact-details h3 {
  font-weight: 700;
}
.contact-details p {
  color: #6a6680;
  font-size: 14px;
  font-weight: 600;
}
.contact-details h6 {
  font-weight: 700;
}
.contact-details h5 {
  font-size: 12px;
  font-weight: 600;
}
.contact-details span {
  color: #9482ff;
}
.contact-details .iconify {
  color: #9482ff;
  font-size: 25px;
  margin-right: 10px;
}
@media (max-width: 800px) {
  .contact-details h1 {
    font-size: 22px;
  }
}

/* About */

.about-details {
  padding-top: 150px;
  background-repeat: no-repeat;
  background-size: contain;
}
.about-details .white {
  color: #fff;
}
.about-details small {
  color: #9482ff;
  font-weight: 600;
}
.about-details h1 {
  font-weight: 700;
  font-size: 25px;
}
.about-details p {
  font-size: 13px;
}
.about-details .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #c582ff;
  font-size: 15px;
  font-weight: 600;
  background-color: #efefef !important;
  border-top: 2px solid #d6d0fd;
  border-right: 2px solid #d6d0fd;
  border-left: 2px solid #d6d0fd;
  border-bottom: 2px solid #efefef;
}
.about-details .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: #7768cc;
  font-weight: 600;
  font-size: 14px;
}
.about-details .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  font-size: 14px;
}
.about-details .tab-pane {
  padding: 10px;
  border-bottom: 1px solid #6a66803a;
  border-right: 1px solid #6a66803a;
  border-left: 1px solid #6a66803a;
}
.about-details .nav {
  justify-content: space-between;
}
.about-details .logos img {
  height: 100px;
  padding: 20px;
}
.about-details .profile img {
  height: 600px;
}
.about-details .card-header {
  background-color: #efefef;
  font-size: 16px;
  padding: 5px;
}
.about-details .card-header button {
  color: #7768cc !important;
  font-size: 14px;
  font-weight: 700;
}
.about-details .card-body {
  background-color: #efefef;
  font-size: 14px;
}
.about-details #accordion .btn {
  white-space: unset;
}
@media (max-width: 800px) {
  .about-details {
    padding-top: 180px;
  }
  .about-details .profile img {
    height: auto;
  }
  .about-details .white {
    color: #000;
  }
}

.navbar {
  background: transparent !important;
  border: 0px;
}
.navbar .nav-link {
  color: #fff !important;
}
.navbar .nav-item {
  padding-right: 10px;
  padding-left: 10px;
}
.navbar-dark .navbar-toggler {
  border-color: #1e1a33 !important;
}
.navbar .logo-nav01 img {
  width: 120px;
  padding: 10px;
}
.navbar .active {
  font-weight: 800;
}
@media (max-width: 800px) {
  .navbar {
    background: #1e1a33 !important;
  }
}
.navbar-fixed-top.scrolled {
  background-color: #1e1a33 !important;
  transition: background-color 200ms linear;
}

.letstalk {
  margin-top: 50px;
}
.letstalk a {
  background-color: #9482ff;
  color: #fff;
  padding: 10px 20px;
  display: inline-block;
  text-decoration: none;
  margin-top: 30px;
}
.letstalk h5 {
  padding-top: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
}
.letstalk h6 {
  color: #fff;
  padding-top: 10px;
}
@media (max-width: 800px) {
  .letstalk h5 {
    font-size: 25px;
  }
  .letstalk a {
    margin-top: 20px;
  }
}

.bg-grad {
  height: 300px;
  background-size: cover;
  background-position: top;
}

